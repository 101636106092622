body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    background: url(/static/media/crossword.b5c5ab66.png);
}

a:link {
  color: #444;
}

a:visited {
  color: #444;
}

a:hover {
  color: #777;
}

a:active {
  color: #444;
}

.deets {
    padding: 0 0 0 10px;
}
