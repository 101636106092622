body {
    background: url(crossword.png);
}

a:link {
  color: #444;
}

a:visited {
  color: #444;
}

a:hover {
  color: #777;
}

a:active {
  color: #444;
}

.deets {
    padding: 0 0 0 10px;
}